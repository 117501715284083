// disabled because we use import of packages where components are layzy loaded
// rule in this case makes no sense with standalone app
/* eslint-disable @nrwl/nx/enforce-module-boundaries */

import { provideContactForm } from "@cg/contact-form";
import { featureOverrideGuard } from "@cg/feature-toggle";
import { provideExitOverlay } from "@cg/olb/exit-overlay";
import { provideOlbState } from "@cg/olb/state";
import { provideTiles } from "@cg/olb/tiles";
import { provideResumeCore } from "@cg/resume-core";
import { KontoroFeatureFlagKeys } from "libs/analytics/src/lib/kontoro/enums/kontoro-feature-flag-keys.enum";
import { provideCarglassSharedState } from "@cg/carglass-shared-state";
import { provideJobApplication } from "@cg/job-application";
import { provideLocations } from "@cg/locations";
import { provideMyCarglass, refreshGuard } from "@cg/my-carglass";
import { provideOlbB2cCore } from "@cg/olb/b2c/core";
import { exitOverlayGuard } from "@cg/olb/core";
import { InitService } from "@cg/olb/shared";
import { kontoroRedirectGuard } from "@cg/shared";
import type { Route } from "@angular/router";
import { ContentPageComponent } from "./app/components/content-page/content-page.component";
import { CarglassOlbInitService } from "./app/services/carglass-olb-init.service";

export const routes: Route[] = [
  {
    path: "",
    component: ContentPageComponent,
    canActivate: [featureOverrideGuard],
    providers: [provideContactForm()]
  },
  {
    path: "standorte",
    loadComponent: () =>
      import("@cg/locations").then((module: typeof import("@cg/locations")) => module.LocationPageComponent),
    data: {
      hideBreadcrumbsInHeader: true
    },
    canActivate: [featureOverrideGuard],
    providers: [provideLocations(), provideCarglassSharedState()]
  },
  {
    path: "resume",
    canActivateChild: [featureOverrideGuard],
    children: [
      {
        path: "expired",
        redirectTo: "/login/error"
      },
      {
        // eslint-disable-next-line sonarjs/no-duplicate-string
        path: ":resume-id",
        redirectTo: "/login/:resume-id"
      }
    ],
    providers: [provideResumeCore(), provideCarglassSharedState()]
  },
  {
    path: "login",
    canActivateChild: [featureOverrideGuard],
    loadComponent: () => import("@cg/my-carglass").then((mod: typeof import("@cg/my-carglass")) => mod.FrameComponent),
    children: [
      {
        path: "",
        loadComponent: () =>
          import("@cg/my-carglass-login").then((mod: typeof import("@cg/my-carglass-login")) => mod.LoginComponent)
      },
      {
        path: "error",
        loadComponent: () =>
          import("@cg/my-carglass-login").then(
            (mod: typeof import("@cg/my-carglass-login")) => mod.MyCarglassErrorComponent
          )
      },
      {
        path: ":resume-id",
        loadComponent: () =>
          import("@cg/my-carglass-login").then((mod: typeof import("@cg/my-carglass-login")) => mod.LoginComponent)
      }
    ],
    providers: [provideMyCarglass(), provideResumeCore(), provideOlbState(), provideCarglassSharedState()]
  },
  {
    path: "olb",
    canDeactivate: [exitOverlayGuard],
    canActivate: [featureOverrideGuard],
    loadComponent: () =>
      import("@cg/olb/b2c/core").then((mod: typeof import("@cg/olb/b2c/core")) => mod.OlbB2cComponent),
    data: {
      hideBreadcrumbsInHeader: true
    },
    providers: [
      provideOlbB2cCore(),
      provideTiles(),
      provideExitOverlay(),
      provideResumeCore(),
      provideCarglassSharedState(),
      provideLocations(),
      { provide: InitService, useClass: CarglassOlbInitService }
    ]
  },
  {
    path: "olb/:yextInfo",
    canDeactivate: [exitOverlayGuard],
    canActivate: [featureOverrideGuard],
    loadComponent: () =>
      import("@cg/olb/b2c/core").then((mod: typeof import("@cg/olb/b2c/core")) => mod.OlbB2cComponent),
    data: {
      hideBreadcrumbsInHeader: true
    },
    providers: [
      provideOlbB2cCore(),
      provideTiles(),
      provideExitOverlay(),
      provideResumeCore(),
      provideCarglassSharedState(),
      provideLocations(),
      { provide: InitService, useClass: CarglassOlbInitService }
    ]
  },
  {
    path: "my-carglass",
    children: [
      {
        path: "login",
        children: [
          {
            path: "",
            redirectTo: "/login",
            pathMatch: "full"
          },
          {
            path: ":resume-id",
            redirectTo: "/login/:resume-id",
            pathMatch: "full"
          }
        ]
      },
      {
        path: "detail",
        loadComponent: () =>
          import("@cg/my-carglass").then((mod: typeof import("@cg/my-carglass")) => mod.FrameComponent),
        children: [
          {
            path: "",
            canActivate: [refreshGuard],
            loadComponent: () =>
              import("@cg/my-carglass").then(
                (mod: typeof import("@cg/my-carglass")) => mod.MyCarglassDetailPageWrapperComponent
              )
          }
        ],
        providers: [provideLocations()]
      },
      {
        path: "",
        loadComponent: () =>
          import("@cg/my-carglass").then((mod: typeof import("@cg/my-carglass")) => mod.FrameComponent),
        children: [
          {
            path: "error",
            loadComponent: () =>
              import("@cg/my-carglass-login").then(
                (mod: typeof import("@cg/my-carglass-login")) => mod.MyCarglassErrorComponent
              )
          }
        ]
      }
    ],
    providers: [provideMyCarglass(), provideResumeCore(), provideOlbState(), provideCarglassSharedState()]
  },
  {
    path: "bewerbung",
    loadComponent: () => import("@cg/my-carglass").then((mod: typeof import("@cg/my-carglass")) => mod.FrameComponent),
    children: [
      {
        path: "",
        loadComponent: () =>
          import("@cg/job-application").then(
            (mod: typeof import("@cg/job-application")) => mod.JobApplicationComponent
          ),
        providers: [provideJobApplication()]
      }
    ]
  },
  {
    path: "chat",
    loadComponent: () => import("@cg/chat-olb").then((mod: typeof import("@cg/chat-olb")) => mod.ChatViewComponent),
    providers: [provideOlbState(), provideLocations()]
  },
  {
    path: "**",
    component: ContentPageComponent,
    providers: [provideContactForm()],
    canActivate: [kontoroRedirectGuard("mobile", KontoroFeatureFlagKeys.CHAT, "variant1", "autoglas/kosten", "chat")]
  }
];
