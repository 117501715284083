<div
  [ngClass]="{
    'mx-auto text-center': content?.alignment === alignment.CENTER
  }"
  class="max-w-[888px]"
>
  <cg-headline [type]="headlineType.h1" [content]="content.headline"></cg-headline>
  <cg-richtext [content]="content.paragraph"></cg-richtext>
  @if (content.ctaLast) {
    <cg-cta class="mb-4 mr-4 t:mb-0" [content]="content.ctaLast"></cg-cta>
  }
  @if (content.ctaFirst) {
    <cg-cta [content]="content.ctaFirst"></cg-cta>
  }
</div>
