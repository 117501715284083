import { environment } from "@cg/environments";
import type { AnalyticsConfiguration } from "@cg/core/interfaces";
import { OptimizelyExperiment } from "@cg/core/enums";

export const analyticsConfiguration: AnalyticsConfiguration = {
  ...environment.analytics,
  gtm: {
    ...environment.analytics.gtm,
    allowedRouterEventUrls:
      /^\/standorte|\/olb|\/olb2b|\/kundenbwertungen|\/login|\/my-carglass|\/bewerbung|\/kfz-mechaniker-autoglas/,
    pageTracking: [
      { pathIsExactly: "/", type: "homepage", name: "homepage" },
      { pathStartsWith: "/olb", skip: true },
      { pathStartsWith: "/autoglas", type: "services" },
      { pathStartsWith: "/unternehmen", type: "company" },
      { pathStartsWith: "/ratgeber", type: "content" },
      { pathStartsWith: "/standorte", type: "search-for-branch" },
      { pathStartsWith: "/login/error", skip: true }, // skip because we track the login error page within the login tracking effects
      { pathStartsWith: "/login", skip: true }, // skip because we track the login page within the login component
      { pathStartsWith: "/my-carglass", type: "my-carglass" },
      { pathStartsWith: "/bewerbung", type: "recruiting" },
      { pathStartsWith: "/kfz-mechaniker-autoglas", type: "recruiting" }
    ]
  },
  optimizely: {
    ...environment.analytics.optimizely,
    experimentScope: {
      [OptimizelyExperiment.LPN_ON_STAGE]: "/"
    },
    experiments: {
      [OptimizelyExperiment.BUSY_BANNER]: [
        { experimentId: "20728940399", variations: ["20686920475"] }, // Production
        { experimentId: "21472240684", variations: ["21490310039"] } // UAT
      ],
      [OptimizelyExperiment.LPN_ON_STAGE]: [{ experimentId: "20697770315", variations: ["20664910455"] }],
      [OptimizelyExperiment.BINDING_BOOKING]: [
        {
          experimentId: "20904950275",
          variations: ["20905050160", "21066150835", "21050721570"]
        }
      ],
      [OptimizelyExperiment.VIN_SCAN]: [
        { experimentId: "21979940095", variations: ["21950740090"] }, // UAT
        { experimentId: "21980080094", variations: ["21947950480"] } // PROD
      ],
      [OptimizelyExperiment.VAPS]: [
        { experimentId: "22327260501", variations: ["22337480303"] }, // UAT
        { experimentId: "22289181642", variations: ["22314490275"] } // PROD
      ],
      [OptimizelyExperiment.OPPORTUNITY_FUNNEL_PRODUCT_NOT_FOUND]: [
        { experimentId: "24655270074", variations: ["24603890543"] }, // Production
        { experimentId: "24631790378", variations: ["24623830290"] } // UAT
      ],
      [OptimizelyExperiment.NEW_APPOINTMENT_TILE]: [
        { experimentId: "24894980678", variations: ["24880860287"] }, // Production
        { experimentId: "24867960704", variations: ["24898400670"] } // UAT
      ],
      [OptimizelyExperiment.NEW_APPOINTMENT_TILE_DESKTOP]: [
        { experimentId: "28170010097", variations: ["28180250089"] }, // Production
        { experimentId: "28202340013", variations: ["28189890080"] } // UAT
      ],
      // AB-Test: OLB_EARLY_CONTACT_DATA - V:OLB_EARLY_CONTACT_DATA_FULL (25883750401, 25885630268, 26216830782, 26223860831)
      [OptimizelyExperiment.OLB_EARLY_CONTACT_DATA_FULL]: [
        { experimentId: "26216830782", variations: ["26223860831"] }, // Production
        { experimentId: "25883750401", variations: ["25885630268"] } // UAT
      ], // AB-Test: OLB_EARLY_CONTACT_DATA - V:OLB_EARLY_CONTACT_DATA_ONLY_MAIL (25883750401, 26168341244, 26216830782, 26273950271)
      [OptimizelyExperiment.OLB_EARLY_CONTACT_DATA_ONLY_MAIL]: [
        { experimentId: "26216830782", variations: ["26273950271"] }, // Production
        { experimentId: "25883750401", variations: ["26168341244"] } // UAT
      ],
      [OptimizelyExperiment.OLB_SUMMARY]: [
        { experimentId: "27800270203", variations: ["27873450037"] }, // Production
        { experimentId: "27856420024", variations: ["27835350189"] } // UAT
      ],
      [OptimizelyExperiment.OLB_CONFIRMATION_CONTENT_RELEVANCY]: [
        { experimentId: "29361290231", variations: ["29342470216"] }, // Production
        { experimentId: "29322770824", variations: ["29361050745"] } // UAT
      ]
    }
  },
  shouldTrackInitialPage: true
};
