import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap, tap } from "rxjs";
import { errorToString } from "@cg/core/utils";
import { ContactEmailInterface } from "../models/contactEmail.interface";
import { ContactFormService } from "../services/contact-form.service";
import * as ContactFormActions from "./contact-form.actions";

@Injectable()
export class ContactFormEffects {
  public sendEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactFormActions.sendContactEmail),
      switchMap(({ payload }: { payload: ContactEmailInterface }) =>
        this.contactFormService.sendEmail(payload).pipe(
          map(() => ContactFormActions.sendContactEmailSuccess()),
          catchError((error: HttpErrorResponse) =>
            of(ContactFormActions.sendContactEmailFailure({ error: errorToString(error) }))
          )
        )
      )
    )
  );

  public sendEmailSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ContactFormActions.sendContactEmailSuccess),
        tap(() => this.router.navigate([this.router.url, "success"]))
      ),
    { dispatch: false }
  );

  public constructor(
    private readonly actions$: Actions,
    private readonly contactFormService: ContactFormService,
    private router: Router
  ) {}
}
